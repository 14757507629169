import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid"; // Import the uuid function to generate unique keys

const MenuReports = ({
  reportsOptions,
  selectedItem,
  setSelectedItem,
  setTableInfo,
  setColumnsToAdd,
  setFiltersToAdd,
  setTitleToCard,
  setFilterState,
  setCheckboxes,
  setSelectAll,
  setListToExport,
  translations,
  t,
}) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const { user } = useSelector((state) => state.globalReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  let language = userSettings?.language ? userSettings.language : "pt";
  const lingua = language.trim();

  useEffect(() => {
    const sidebarContainer = document.getElementById("sidebarContainer");
    if (sidebarContainer) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === "style") {
            const displayStyle =
              window.getComputedStyle(sidebarContainer).display;
            setIsSidebarVisible(displayStyle === "block");
          }
        });
      });

      observer.observe(sidebarContainer, { attributes: true });

      return () => observer.disconnect();
    }
  }, []);

  const handleClickItem = (item) => {
    if (item === selectedItem) return;
    setTableInfo([]);
    setColumnsToAdd([]);
    setFiltersToAdd([]);
    setTitleToCard("");
    setFilterState({});
    setCheckboxes([]);
    setSelectAll(false);
    setListToExport([]);
    setSelectedItem(item);
  };

  const getSelected1Lvl = (item) => {
    return item === selectedItem
      ? "menu-item-wrap-lvl2 selected-lvl2"
      : "menu-item-wrap-lvl2 false";
  };

  return (
    isSidebarVisible && (
      <>
        <div className="title">{t("portalrh.reports.reports")}</div>
        {reportsOptions.length > 0 &&
          reportsOptions.map((report) => {
            const reportKey = Object.keys(report)[0];
            const reportDetails = report[reportKey];
            const hasPermission = user.roles.some(
              (role) =>
                role.label === reportDetails.application &&
                role.permissions.some(
                  (permission) => permission.label === reportDetails.permissions
                )
            );
            return (
              hasPermission && (
                <div
                  key={uuid()}
                  className={getSelected1Lvl(report)}
                  onClick={() => handleClickItem(report)}
                >
                  {Object.keys(report).map((key) => (
                    <div key={key}>
                      {translations[lingua] && translations[lingua][key]
                        ? translations[lingua][key]
                        : key}
                    </div>
                  ))}
                </div>
              )
            );
          })}
      </>
    )
  );
};

export default withNamespaces()(MenuReports);
