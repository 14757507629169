import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import { setGenericRequestState } from "~/store/ducks/portalrh/actionTypes";
import MenuReports from "./Menu";
import CardReports from "./CardReports";

const Reports = ({
  portalRhMenus,
  designacao,
  sectionName,
  searchFilter,
  activeSelected,
}) => {
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { reportsToExport } = useSelector(
    (state) => state.reportReducer
  );
  const [reportsOptions, setReportsOptions] = useState([]);
  const [translations, setTranslations] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [containMenu, setContainMenu] = useState(false);
  const [menus, setMenus] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableInfo, setTableInfo] = useState([]);
  const [columnsToAdd, setColumnsToAdd] = useState([]);
  const [filtersToAdd, setFiltersToAdd] = useState([]);
  const [titleToCard, setTitleToCard] = useState("");
  const [filterState, setFilterState] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [listToExport, setListToExport] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (menu) {
      let app = "";
      //find app from url
      const arr1 =
        window.location.href.includes("/uniksystem/") &&
        window.location.href.split("/uniksystem/");
      if (arr1.length > 1) {
        const arr2 = arr1[1];
        const arr3 = arr2.split("/");
        app = arr3[0];
      }
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/reports` || item.route === `${app}/reports`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const reportsExtraConfiguration_ =
          extraConfiguration_ && extraConfiguration_.reports;
        setReportsOptions(reportsExtraConfiguration_);
        const translationsReportsExtraConfiguration_ =
          extraConfiguration_ && extraConfiguration_.translations;
        setTranslations(translationsReportsExtraConfiguration_);
      }
    }
  }, [menu]);

  useEffect(() => {
    /*close full screen iframes*/
    setMenus({});
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
    let requestState = localStorage.getItem("requestState");
    if (requestState != null) {
      if (requestState.startsWith('"') && requestState.endsWith('"')) {
        requestState = JSON.parse(requestState);
      }
      dispatch(setGenericRequestState(requestState));
    }
    //localStorage.removeItem("requestState")
  }, []);

  useEffect(() => {
    const foundItem =
      reportsOptions.length > 0 &&
      reportsOptions.find((report) => report === selectedItem);
    if (foundItem) {
      Object.keys(foundItem).forEach((key) => {
        setColumnsToAdd(foundItem[key].columns);
        setFiltersToAdd(foundItem[key].filters);
        setTitleToCard(key);
      });
    }
  }, [selectedItem, reportsOptions]);

  useEffect(() => {
    setTableInfo(reportsToExport);
  }, [reportsToExport]);
  return (
    <div style={{ display: "flex" }}>
      <div className="menu-div-lvl2">
        <MenuReports
          reportsOptions={reportsOptions}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setTableInfo={setTableInfo}
          setColumnsToAdd={setColumnsToAdd}
          setFiltersToAdd={setFiltersToAdd}
          setTitleToCard={setTitleToCard}
          filterState={filterState}
          setFilterState={setFilterState}
          setCheckboxes={setCheckboxes}
          setSelectAll={setSelectAll}
          setListToExport={setListToExport}
          translations={translations}
        />
      </div>
      {titleToCard !== "" && (
        <div style={{ height: "80vh", width: "-webkit-fill-available" }}>
          <CardReports
            titleToCard={titleToCard}
            tableInfo={tableInfo}
            columnsToAdd={columnsToAdd}
            filtersToAdd={filtersToAdd}
            isMobile={isMobile}
            filterState={filterState}
            setFilterState={setFilterState}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            listToExport={listToExport}
            setListToExport={setListToExport}
            translations={translations}
            selectedItem={selectedItem}
          />
        </div>
      )}
    </div>
  );
};

export default Reports;
