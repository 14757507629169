import printJS from "print-js";

export const abbreviateString = (value, sizeAbrev) => {
  return value.length > sizeAbrev ? value.slice(0, sizeAbrev - 1) + "…" : value;
};

export const toLocaleString = (dateStr) =>
  dateStr ? new Date(dateStr).toLocaleString("pt-PT") : "";
export const toLocaleStringHistory = (dateStr) =>
  dateStr != null ? new Date(dateStr).toLocaleString("pt-PT") : "------------";

export const toLocaleDateString = (dateStr) =>
  dateStr ? new Date(dateStr).toLocaleDateString("pt-PT") : "";

export const buildDocNumberColumn = (cell, row) => {
  return row?.metadata?.document_identifier;
};

export const buildTotalAmoutColumn = (cell, row) => {
  return row?.metadata?.amount_total;
};

export const buildVendorNameColum = (cell, row) => {
  return row?.metadata?.supplier_name;
};

export const createBase64StringFromBinary = (data) => {
  console.log(data.charAt(0));
  if (data.charAt(0) == "P") {
    return "data:image/svg+xml;base64," + data; //"svg"
  } else if (data.charAt(0) == "/") {
    return "data:image/jpeg;base64," + data; //"jpeg";
  } else if (data.charAt(0) == "R") {
    return "data:image/gig;base64," + data; //"gif";
  } else if (data.charAt(0) == "i") {
    return "data:image/png;base64," + data; //"png";
  } else if (data.charAt(0) == "J") {
    return "data:application/pdf;base64," + data; //"pdf";
  }
};

// Função para obter o CSS das classes encontradas
const getCSS = (classesArray) => {
  let styles = "";
  for (const styleSheet of document.styleSheets) {
    try {
      if (styleSheet.cssRules) {
        for (const rule of styleSheet.cssRules) {
          // Verifica se a regra de estilo contém alguma das classes
          if (
            classesArray.some((className) => rule.cssText.includes(className))
          ) {
            styles += rule.cssText;
          }
        }
      }
    } catch (e) {
      console.log("Não foi possível acessar alguns estilos externos", e);
    }
  }
  return styles;
};

// Função para extrair todas as classes de um elemento ref
const getAllClassesFromRef = (ref) => {
  const classArray = [];
  if (ref) {
    // Seleciona todos os elementos dentro do ref que possuem a propriedade class
    const elementsWithClass = ref.querySelectorAll("[class]");
    elementsWithClass.forEach((element) => {
      // Extrai todas as classes e adiciona ao array
      element.classList.forEach((className) => {
        if (!classArray.includes(className)) {
          classArray.push(className);
        }
      });
    });
  }
  return classArray;
};

// Função para imprimir usando o print-js
export const handlePrint = (ref, state) => {
  if (ref) {
    state(true);

    const contentToPrint = ref.innerHTML;
    const classesToFind = getAllClassesFromRef(ref);
    const stylesGetted = getCSS(classesToFind);

    printJS({
      printable: contentToPrint,
      type: "raw-html",
      style: `
        @page { 
          size: auto; 
          margin: 10mm;
          background: white;
        }

        html, body {
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }

        ${stylesGetted}
      `,
      documentTitle: "Uniksystem",
      onLoadingStart: () => {
        state(true); // Aqui, redefine o estado
      },
      onLoadingEnd: () => {
        state(false); // Aqui, redefine o estado
      },
      onError: (error) => {
        console.error("Erro ao imprimir", error);
        state(false); // Garantindo que o estado é redefinido em caso de erro
      },
    });
  } else {
    console.error("componentRef.current não está definido.");
  }
};
